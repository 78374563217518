<template>
  <v-container
    fluid
    grid-list-xl
    class="funds"
  >
    <toolbar
      :title="$t('funds.funds')"
      :search.sync="search"
      :search-label="$t('funds.searchLabel')"
      @search="getFunds"
    >
      <template v-slot:preSearch>
        <v-divider
          vertical
          class="mr-4 ml-2"
        ></v-divider>
        <v-text-field
          v-model="kvg"
          label="KVG"
          outlined
          hide-details
          class="shrink mx-2"
          clearable
          @keydown.enter="getFunds"
        ></v-text-field>
        <v-select
          v-model="assetClass"
          :placeholder="$t('funds.assetClasses.all')"
          :label="$t('funds.assetClass')"
          :items="[
            {text: $t('funds.assetClasses.all'), value: false},
            ...assetClasses.map(el=>{
              return {text: $t('funds.assetClasses.' + el), value: el}
            }),
          ]
          "
          outlined
          class="shrink mx-2 asset-class"
          hide-details
          @change="getFunds"
        ></v-select>
      </template>
    </toolbar>

    <v-layout
      row
      wrap
    >
      <v-data-table
        :items="funds"
        :headers="tableHeaders"
        :options.sync="pageOptions"
        :footer-props="{
          itemsPerPageOptions: [20,40,100]
        }"
        item-key="dq"
        class="user-table grow"
        :locale="$store.state.locale"
        :server-items-length="fundsCount"
        :loading="loading.main"
        @click:row="openDialog"
      >
        <template v-slot:item.assetClass="{item}">
          {{ $t('funds.assetClasses.' + item.assetClass.toUpperCase()) }}
        </template>
        <template v-slot:item.annual="{item}">
          <FundTableDocumentField
            :item="item"
            document-type="annual"
          />
        </template>
        <template v-slot:item.semiAnnual="{item}">
          <FundTableDocumentField
            :item="item"
            document-type="semiAnnual"
          />
        </template>
        <template v-slot:item.kid="{item}">
          <FundTableDocumentField
            :item="item"
            document-type="kid"
          />
        </template>
        <template v-slot:item.prospect="{item}">
          <FundTableDocumentField
            :item="item"
            document-type="prospect"
          />
        </template>
        <template v-slot:item.factsheet="{item}">
          <FundTableDocumentField
            :item="item"
            document-type="factsheet"
          />
        </template>
        <template v-slot:item.beiblatt="{item}">
          <FundTableDocumentField
            :item="item"
            document-type="beiblatt"
          />
        </template>
      </v-data-table>
      <v-dialog
        v-model="showDialog"
        max-width="1000"
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card
          v-if="activeFund"
          outlined
          rounded
        >
          <v-card-title>
            <v-container>
              <v-row>
                <v-col>
                  <v-row>
                    <v-col>
                      {{ activeFund.name }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0 my-0">
                      <span
                        class="grey--text"
                        style="font-size: 1rem"
                      >ISIN: {{ activeFund.ids.isin }} | WKN: {{ activeFund.ids.wkn }}</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <close-button
                    @click="closeDialog()"
                  >
                  </close-button>
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>
          <v-card-text>
            <v-tabs
              v-model="dialogTab"
            >
              <v-tab href="#tab-docs">
                {{ $t('funds.reports.documents') }}
              </v-tab>
              <v-tab
                href="#tab-fractions"
              >
                {{ $t('funds.fractions.fractions') }}
              </v-tab>
              <v-tab href="#tab-sales">
                {{ $t('funds.sales.support') }}
              </v-tab>
              <!--              <v-tab-->
              <!--                href="#tab-ec"-->
              <!--              >-->
              <!--                {{ $t('funds.ec.equivalenceCheck') }}-->
              <!--              </v-tab>-->
            </v-tabs>
            <v-divider></v-divider>
            <v-tabs-items v-model="dialogTab">
              <v-tab-item value="tab-docs">
                <v-row

                  class="my-2"
                >
                  <v-col
                    v-for="documentType in documentTypes"
                    :key="documentType.id"
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <FundDocumentEditor
                      :value="activeFund.reports[documentType.name]"
                      :document-type="documentType"
                      :dq="activeFund.dq"
                      @input="$set(activeFund.reports, documentType.name, $event)"
                      @invalidateFile="getFunds()"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item
                value="tab-fractions"
              >
                <v-container
                  fluid
                >
                  <v-row
                    v-if="fractions"
                    class="mt-1"
                  >
                    <v-col
                      v-for="fractionsConfigEntry in fractionsConfig"
                      :key="fractionsConfigEntry"
                      cols="12"
                      sm="6"
                      md="4"
                      lg="3"
                    >
                      <v-text-field
                        v-if="fractions"
                        :value="fractions.fractions[fractionsConfigEntry.toUpperCase()]"
                        filled
                        hide-details
                        :label="$t(`funds.fractions.${fractionsConfigEntry}`)"
                        type="number"
                        @input="fractions.fractions[fractionsConfigEntry.toUpperCase()] = $event "
                      >
                        <template
                          v-slot:append
                        >
                          <div class="pt-1">
                            %
                          </div>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                  <v-row
                    align="center"
                  >
                    <v-col>
                      <span>{{ $t('funds.fractions.total') }}:&nbsp;{{ summedFractions }}%<br></span>
                      <span
                        v-if="summedFractions !== 100"
                        class="error--text flex-nowrap"
                      >{{ $t('funds.fractions.sumCondition') }}</span>
                    </v-col>
                    <v-col class="shrink">
                      <v-btn
                        :disabled="summedFractions !== 100"
                        color="primary"
                        right
                        @click="saveFractions()"
                      >
                        {{ $t('actions.save') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item value="tab-sales">
                <v-row
                  v-if="ad && ad.contacts"
                  class="my-2"
                >
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-card
                      outlined
                      :loading="loading.ad"
                    >
                      <v-expansion-panels
                        accordion
                        mandatory
                        flat
                        hover
                      >
                        <v-expansion-panel>
                          <v-expansion-panel-header
                            class="pa-0"
                            hide-actions
                          >
                            <v-card-title>
                              <v-icon class="mr-2">
                                comment
                              </v-icon>
                              {{ $t(`funds.sales.comment`) }}
                            </v-card-title>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content class="px-0">
                            <v-card-text class="pa-0">
                              <v-textarea
                                v-model="ad.issuerComment"
                                hide-details
                                rows="16"
                                flat
                                placeholder="..."

                                filled
                              ></v-textarea>
                            </v-card-text>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header
                            class="pa-0"
                            hide-actions
                          >
                            <v-card-title>
                              <v-icon class="mr-2">
                                trending_up
                              </v-icon>
                              {{ $t(`funds.sales.investmentStrategy`) }}
                            </v-card-title>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-card-text class="pa-0">
                              <v-textarea
                                v-model="ad.investmentStrategy"
                                hide-details
                                rows="16"
                                flat
                                placeholder="..."
                                filled
                              ></v-textarea>
                            </v-card-text>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                      >
                        <v-card
                          outlined
                          :loading="loading.ad"
                        >
                          <v-card-title>
                            <v-icon class="mr-2">
                              people
                            </v-icon>
                            {{ $t(`funds.sales.contacts`) }}
                          </v-card-title>
                          <v-divider></v-divider>
                          <template
                            v-if="ad.contacts.length > 0 && contacts.length > 0"
                          >
                            <v-card-text
                              class="py-0"
                            >
                              <draggable
                                v-model="ad.contacts"
                                tag="div"
                                class="v-list v-list--dense"
                                v-bind="{
                                  animation: 200,
                                  group: 'contacts',
                                  disabled: false,
                                  ghostClass: 'ghost',
                                }"
                                @start="drag = true"
                                @end="drag = false"
                              >
                                <transition-group
                                  type="transition"
                                  :name="!drag ? 'flip-list' : null"
                                >
                                  <v-list-item
                                    v-for="(contact, contactKey) in enrichedContacts"
                                    :key="contact.id"
                                    class="contact-item px-0"
                                  >
                                    <v-list-item-avatar
                                      style="cursor: move"
                                    >
                                      <v-avatar>
                                        <img :src="duseUrl(contact.pictureDuseId)">
                                      </v-avatar>
                                    </v-list-item-avatar>

                                    <v-list-item-content
                                      style="cursor: move"
                                    >
                                      <v-list-item-title v-text="contact.name"></v-list-item-title>
                                      <v-list-item-subtitle v-text="contact.mail"></v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                      <v-btn
                                        icon
                                        plain
                                        @click="ad.contacts.splice(contactKey, 1)"
                                      >
                                        <v-icon color="error">
                                          clear
                                        </v-icon>
                                      </v-btn>
                                    </v-list-item-action>
                                  </v-list-item>
                                </transition-group>
                              </draggable>
                            </v-card-text>
                            <v-divider></v-divider>
                          </template>

                          <v-card-actions>
                            <v-select
                              v-model="selectedContact"
                              :label="$t('funds.sales.addContact')"
                              :items="availableContacts"
                              item-text="name"
                              item-value="id"
                              outlined
                              rounded
                              dense
                              hide-details
                              prepend-icon="person_add"
                              @change="el => ad.contacts.push(el)"
                              @blur="selectedContact = null"
                            >
                              <template v-slot:item="{item}">
                                <v-list-item-avatar>
                                  <v-avatar>
                                    <img :src="duseUrl(item.pictureDuseId)">
                                  </v-avatar>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <v-list-item-title v-text="item.name"></v-list-item-title>
                                  <v-list-item-subtitle v-text="item.mail"></v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </v-select>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <v-card
                          outlined
                          :loading="loading.ad"
                        >
                          <v-card-title>
                            <v-icon class="mr-2">
                              description
                            </v-icon>
                            {{ $t(`funds.sales.files`) }}
                          </v-card-title>
                          <v-divider></v-divider>
                          <template
                            v-if="ad.files && (ad.files.length > 0)"
                          >
                            <v-card-text
                              class="py-0"
                            >
                              <draggable
                                v-model="ad.files"
                                tag="div"
                                class="v-list v-list--dense"
                                v-bind="{
                                  animation: 200,
                                  group: 'files',
                                  disabled: false,
                                  ghostClass: 'ghost',
                                }"
                                handle=".handle"
                                @start="drag = true"
                                @end="drag = false"
                              >
                                <transition-group
                                  type="transition"
                                  :name="!drag ? 'flip-list' : null"
                                >
                                  <v-list-item
                                    v-for="(file, fileKey) in ad.files"
                                    :key="fileKey"
                                    class="contact-item px-0 mx-0"
                                  >
                                    <v-list-item-avatar
                                      width="30"
                                      min-width="30"
                                      class="handle mx-0"
                                      style="cursor: move"
                                    >
                                      <v-list-item-avatar
                                        width="30"
                                        class="mr-0"
                                      >
                                        <v-icon>drag_handle</v-icon>
                                      </v-list-item-avatar>
                                    </v-list-item-avatar>
                                    <v-btn
                                      v-if="file.type == 'derifiles-link'"
                                      link
                                      target="_blank"
                                      :href="`${getEnvironment}/${file.derifilesId}`"
                                      class="mx-2"
                                      icon
                                      color="accent"
                                      plain
                                    >
                                      <v-icon>
                                        open_in_new
                                      </v-icon>
                                    </v-btn>
                                    <v-btn 
                                      v-else-if="file.type === 'external-file-link'"
                                      link
                                      target="_blank"
                                      :href="file.href"
                                      class="mx-2"
                                      icon
                                      color="accent"
                                      plain
                                    >
                                      <v-icon>
                                        open_in_new
                                      </v-icon>
                                    </v-btn>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        <v-text-field
                                          v-model="file.name"
                                          flat
                                          style="font-weight: normal"
                                          hide-details
                                          outlined
                                          dense
                                        ></v-text-field>
                                      </v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-action class="ml-1">
                                      <v-btn
                                        icon
                                        plain
                                        @click="ad.files.splice(fileKey, 1)"
                                      >
                                        <v-icon color="error">
                                          clear
                                        </v-icon>
                                      </v-btn>
                                    </v-list-item-action>
                                  </v-list-item>
                                </transition-group>
                              </draggable>
                            </v-card-text>
                            <v-divider></v-divider>
                          </template>
                          <v-card-actions>
                            <v-file-input
                              v-model="newFile.data"
                              style="cursor: pointer !important;"
                              :label="$t('funds.reports.selectFile')"
                              rounded
                              :class="{shrink: newFile.data !== null}"
                              :hide-input="newFile.data !== null"
                              accept=".pdf"
                              full-width
                              hide-details
                              outlined
                              dense
                              @change="newFile.name = newFile.data.name"
                            ></v-file-input>
                            <v-text-field
                              v-if="newFile.data"
                              v-model="newFile.name"
                              :label="$t('funds.sales.label')"
                              persistent-hint
                              rounded
                              autofocus
                              outlined
                              hide-details
                              dense
                              color="yellow darken-3"
                              append-outer-icon="clear"
                              @click:append-outer="newFile.data = null"
                            ></v-text-field>
                            <v-btn
                              :disabled="!newFile.name"
                              color="primary"
                              outlined
                              plain
                              class="ml-5 mt-1"
                              @click="uploadAdFile"
                            >
                              Upload
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-col class="shrink">
                        <v-btn
                          color="primary"
                          @click="updateAd"
                        >
                          {{ $t('actions.save') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tab-item>

              <!--              <v-tab-item value="tab-ec">-->
              <!--                <equivalence-check-->
              <!--                  v-model="ec"-->
              <!--                  :product-dq="activeFundDq"-->
              <!--                  @saveEc="saveEc"-->
              <!--                ></equivalence-check>-->
              <!--              </v-tab-item>-->
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>


<script>
  import Toolbar
    from "@/components/common/Toolbar"
  import {
    mapActions,
  } from "vuex"
  import FundTableDocumentField
    from "@/components/funds/FundTableDocumentField"
  import FundDocumentEditor
    from "@/components/funds/FundDocumentEditor"
  import CloseButton
    from "@/components/stocks/CloseButton"
  import EquivalenceCheck
    from "@/components/equivalence-check/EquivalenceCheck"
  import draggable
    from 'vuedraggable'

  export default {
    name: "Funds",
    components: {
      EquivalenceCheck,
      CloseButton,
      FundDocumentEditor,
      FundTableDocumentField,
      Toolbar,
      draggable,
    },
    data() {
      return {
        selectedContact: null,
        querySessionId: null,
        ec: null,
        dialogTab: null,
        showDialog: false,
        activeFundDq: null,
        kvg: null,
        assetClass: false,
        selection: null,
        search: null,
        expanded: [],
        fundsCount: null,
        loading: {main: true, ad: true},
        drag: false,
        newFile: {
          data: null,
          name: null,
        },
        pageOptions: {
          page: 1,
          itemsPerPage: 20,
        },
        defaultEc: {
          dq: this.productDq,
          riskBasedManagement: false,
          bviFundCategory: 'MA_EURW',
          recommendedHoldingPeriod: null,
          strategy: {
            thematic: false,
            quantitative: false,
            fundamental: false,
          },
          objective: {
            capitalProtection: false,
            targetYield: false,
          },
          sustainability: 'O',
          sustainabilityInfo: '',
          sustainabilityRiskInfo: '',
          currencyHedge: false,
          stockNeutralPercentage: 0,
        },
        assetClasses: [
          'BOND',
          'EQUITY',
          'MIXED',
          'REAL_ESTATE',
          'MONEY_MARKET',
          'LIFECYCLE',
          'COMMODITIES',
          'GUARANTEE',
          'OTHERS',
        ],
        documentTypes: [
          {
            name: 'annual',
            id: 'ANNUAL',
          },
          {
            name: 'semiAnnual',
            id: 'SEMIANNUAL',
          },
          {
            name: 'prospect',
            id: 'PROSPECT',
          },
          {
            name: 'kid',
            id: 'BIB',
          },
          {
            name: 'factsheet',
            id: 'ISSUER_FACTSHEET',
          },
          {
            name: 'beiblatt',
            id: 'BEIBLATT',
          },
        ],
        funds: [],
        fractions: null,
        ad: {
          contacts: [],
          files: [],
          issuerComment: '',
          dq: null,
          investmentStrategy: '',
        },
        defaultAd:  {
          contacts: [],
          files: [],
          issuerComment: '',
          dq: null,
          investmentStrategy: '',
        },
        contacts: [],
        fractionsConfig: [
          'liquidity', 'bonds', 'stocks', 'real_estate', 'commodities', 'holdings', 'others',
        ],
      }
    },
    computed: {
      getEnvironment(){
        return process.env.VUE_APP_API_DERIFILES_ALT
      },
      availableContacts() {
        return this.contacts.filter(el => !(this.ad.contacts.indexOf(el.id) >= 0))
      },
      enrichedContacts() {
        return this
          .ad
          .contacts
          .map(id => this.contacts.find(el => el.id === id))
          .filter(el => el != undefined && el != null)
      },
      summedFractions() {
        if (!this.fractions) {
          return null
        }
        return Object.values(this.fractions.fractions).reduce((el, sum) => {
          return parseFloat(sum) + parseFloat(isNaN(el) ? 0 : el)
        })
      },
      activeFund() {
        return this.activeFundDq ? this.funds.find(el => el.dq === this.activeFundDq) : null
      },
      tableHeaders() {
        return [
          {
            text: 'ISIN',
            value: 'ids.isin',
            sortable: false,
          },
          {
            text: 'WKN',
            value: 'ids.wkn',
            sortable: false,
          },
          {
            text: 'KVG',
            value: 'kvg',
            sortable: false,
          },
          {
            text: 'Name',
            value: 'name',
            sortable: false,
          },
          {
            text: this.$t('funds.assetClass'),
            value: 'assetClass',
            sortable: false,
          },
          {
            text: this.$t('funds.reports.annual'),
            value: 'annual',
            sortable: false,
          },
          {
            text: this.$t('funds.reports.semiAnnual'),
            value: 'semiAnnual',
            sortable: false,
          },
          {
            text: this.$t('funds.reports.prospect'),
            value: 'prospect',
            sortable: false,
          },
          {
            text: this.$t('funds.reports.kid'),
            value: 'kid',
            sortable: false,
          },
          {
            text: this.$t('funds.reports.factsheet'),
            value: 'factsheet',
            sortable: false,
          },
          {
            text: this.$t('funds.reports.beiblatt'),
            value: 'beiblatt',
            sortable: false,
          },
          {
            text: '',
            value: 'json',
            sortable: false,
            class: 'button-column',
          },
        // {text: '', value: 'data-table-expand', sortable: false, class: 'button-column'},
        ]

      },
    },
    watch: {
      showDialog(n) {
        if (!n) {
          this.closeDialog()
        } else {
          this.fetchEc()
        }
      },
      pageOptions: {
        async handler(e) {
          if(!this.loading.main) {
            await this.getFundPage()
          }
        },
        deep: true,
      },
    },
    mounted() {
      this.getFunds()
    },
    methods: {
      ...mapActions(['initFundQuerySession', 'countProductsBySession', 'getProductsBySession', 'saveAd', 'uploadFileSimple', 'fetchFund', 'updateEquivalenceCheckData', 'getEquivalenceCheckData', 'fetchFractions', 'updateFractions', 'fetchAdvt', 'fetchContacts', 'showSnackbar']),
      async fetchEc() {
        this.$set(this, 'ec', await this.getEquivalenceCheckData(this.activeFundDq))

        if (this.ec.manual === null) {
          this.$set(this.ec, 'manual', JSON.parse(JSON.stringify(this.defaultEc)))
        }

        ['objective', 'strategy'].map(el => {
          if (this.ec.manual[el] === null) {
            this.$set(this.ec.manual, el, {})

          }
        })
      },
      async saveEc() {
        let result = await this.updateEquivalenceCheckData({
          dq: this.activeFundDq,
          data: this.ec.manual,
        })
        if (result !== null) {
          this.showSnackbar({text: this.$t('funds.ec.saved')})
        } else {
          this.showSnackbar({text: 'funds.'})
        }
      },
      async updateAd() {
        try {
          let result = await this.saveAd({
            dq: this.activeFundDq,
            data: {...this.ad, dq: this.activeFundDq},
          })

          if (result !== null) {
            this.showSnackbar({text: this.$t('actions.saved')})
          } else {
            this.showSnackbar({text: 'actions.error'})
          }
        }
        catch (e) {
          this.showSnackbar({text: 'actions.error'})
        }
      },
      async uploadAdFile() {
        try {
          let result = await this.uploadFileSimple({
            file: this.newFile.data,
          })
          this.ad.files.push({
            name: this.newFile.name,
            derifilesId: result.data.data.derifilesId,
            type: "derifiles-link",
          })
          await this.updateAd()
        } catch (e) {
          console.log(e)
          this.showSnackbar({
            text: this.$t('funds.reports.uploadError', [this.newFile.name]),
            type: 'error',
          })
        }

        this.newFile = {
          data: null,
          name: null,
        }
      },
      async saveFractions() {
        const result = await this.updateFractions({
          dq: this.activeFundDq,
          data: this.fractions,
        })
        if (result) {
          this.fractions = result
          this.showSnackbar({
            text: this.$t('funds.fractions.saved'),
          })
        } else {
          this.showSnackbar({
            text: this.$t('funds.fractions.error'),
            type: 'error',
          })
        }
      },
      async openDialog(fund) {
        this.activeFundDq = fund.dq
        this.showDialog = true
        this.loading.main = true
        this.loading.ad = true

        const fractions = await this.fetchFractions(fund.dq)
        const advt = await this.fetchAdvt(fund.dq)
        const defaultFractions = this.fractionsConfig.reduce((sum, el) => {
          sum[el.toUpperCase()] = 0
          return sum
        }, {})

        if (fractions) {
          this.fractions = fractions
          this.fractions.fractions = {...defaultFractions, ...fractions.fractions}
        } else {
          this.fractions = {
            productDq: this.activeFundDq,
            fractions: defaultFractions,
            institute: 'DEFAULT',
            date: new Date().toISOString(),
          }
        }
        this.loading.main = false

        this.contacts = await this.fetchContacts(fund.dq)

        if (advt) {
          this.ad = advt
        }
        this.loading.ad = false

      },
      closeDialog() {
        this.ad = JSON.parse(JSON.stringify(this.defaultAd))
        this.dialogTab = 0
        this.showDialog = false
        this.fractions = null
        this.activeFundDq = null
        this.dialogTab = null
        this.newFile = {data: null, name: null}
      },
      async getFunds() {
        this.querySessionId == null
        this.loading.main = true

        const sessionId = await this.initFundQuerySession({
          search: this.search || false,
          kvg: this.kvg || false,
          assetClass: this.assetClass || false,
        });

        if(sessionId == null) {
          return // abort if the session initialization failed
        }
        
        this.querySessionId = sessionId;

        const count = await this.countProductsBySession(sessionId)
        if (count != null) {
          this.fundsCount = count
        }

        this.loading.main = false

        if(this.pageOptions.page == 1) {
          this.getFundPage()
        } else {
          this.$set(this.pageOptions, 'page', 1) // will also trigger product data
        }
      },
      async getFundPage() {
        if(this.querySessionId == null) {
          this.getFunds()
        } else {
          this.loading.main = true
          const data = await this.getProductsBySession({
            sessionId: this.querySessionId,
            limit: this.pageOptions.itemsPerPage,
            offset: (this.pageOptions.page - 1) * this.pageOptions.itemsPerPage,
          })
          this.funds = data
          this.loading.main = false
        }
      },

      async getSingleFundData(dq) {
        const fundIndex = this.funds.findIndex(el => el.dq === dq)
        this.funds[fundIndex] = await this.fetchFund(dq)
      },
      getDocumentDate(item, documentType) {

        let document = item.reports.find(el => el.type === documentType)

        if (document) {
          return new Date(document.date).toLocaleDateString(this.$store.state.locale)
        } else {
          return null
        }
      },
      duseUrl(imgId) {
        return process.env.VUE_APP_API_DERIFILES_PUBLIC + "/" + imgId
      },
    },
  }
</script>

<style lang="scss">
.funds {

  .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    box-shadow: inset 0 0 8px -5px rgb(50 50 50 / 75%), inset 0 0 8px -5px rgb(50 50 50 / 75%);
  }

  .v-data-table {
    tr {
      cursor: pointer;
    }
  }
}


.v-data-table__expanded__row {
  td {
    border-top: 1px solid #aaa;

    &:first-child {
      border-left: 1px solid #aaa;
    }

    &:last-child {
      border-right: 1px solid #aaa;
    }
  }
}

.v-expansion-panel-content__wrap {
  padding: 0;
}

.v-data-table__expanded__content {
  td {
    border-bottom: 1px solid #aaa !important;
    border-left: 1px solid #aaa;
    border-right: 1px solid #aaa;
  }
}

.asset-class {
  max-width: 230px
}
</style>
